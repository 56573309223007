import React, { createRef, RefObject, useEffect, useState } from "react";
import domHelpers from "src/utils/domHelpers";

const fixMdb = (ref: RefObject<HTMLDivElement>) => {
	const src =
		"https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/2.2.1/mdb.min.js";
	domHelpers.removeScriptTag(src);
	setTimeout(() => {
		ref.current?.querySelectorAll(".form-notch").forEach((e) => e.remove());
		domHelpers.addScriptTag(src, false);
	}, 0);
};

interface IProps {}

export const FormikWrapper = (props: React.PropsWithChildren<IProps>) => {
	const ref = createRef<HTMLDivElement>();
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (!isLoaded) {
			fixMdb(ref);
			setIsLoaded(true);
		}
	}, [isLoaded, ref]);

	return <div ref={ref}>{props.children}</div>;
};
