import React from "react";
import { Button } from "reactstrap";

export enum SaveButtonTypes {
	Submit = "submit",
	Button = "button",
}

interface IProps {
	saveButtonType?: SaveButtonTypes;
	onSaveAsync?: () => Promise<any>;
	onCancelAsync: () => Promise<any> | void;
}

export const SaveCancel = (props: IProps) => {
	return (
		<>
			<Button
				type={props.saveButtonType || "button"}
				color="primary"
				className="me-h"
				onClick={() => {
					props.onSaveAsync && props.onSaveAsync();
					return true;
				}}
			>
				Save
			</Button>
			<Button
				type="button"
				color="secondary"
				onClick={() => props.onCancelAsync()}
			>
				Cancel
			</Button>
		</>
	);
};
