import React from "react";
import { Col, Row } from "reactstrap";
import { UserDto } from "src/api/models/dto/users/userDto";
import { AccountInfo } from "./accountInfo";
import { PersonalInfo } from "./personalInfo";
import { ProfilePicture } from "./profilePicture";

export interface IProfileComponentProps {
	user: UserDto;
}

export const Profile = (props: IProfileComponentProps) => {
	const { user } = props;
	return (
		<>
			<Row className="mb-2">
				<Col>
					<ProfilePicture user={user} />
				</Col>
			</Row>
			<Row>
				<Col xs="12" lg="6" className="mb-2">
					<PersonalInfo user={user} />
				</Col>
				<Col xs="12" lg="6" className="mb-2">
					<AccountInfo user={user} />
				</Col>
			</Row>
		</>
	);
};
