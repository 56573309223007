import classnames from "classnames";
import React, { useContext, useState } from "react";
import {
	Col,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from "reactstrap";
import { UserContext } from "src/components/context/userContext";
import { Profile } from "src/components/user/profile/profile";
import { Signin } from "src/components/user/signin";

interface IProps {}

export const ProfileView = (props: IProps) => {
	const [activeTab, setActiveTab] = useState("1");

	const toggle = (tab: string) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const userContext = useContext(UserContext);

	if (!userContext?.user) {
		return <Signin returnUrl="/" />;
	}
	const { user } = userContext;

	return (
		<Row>
			<Col>
				<Nav tabs fill={false} justified={false} className="mb-1">
					<NavItem>
						<NavLink
							className={classnames({
								active: activeTab === "1",
								"bg-white": activeTab === "1",
								clickable: true,
								"font-1_5": true,
								"rounded-top": true,
							})}
							onClick={() => {
								toggle("1");
							}}
						>
							Profile
						</NavLink>
					</NavItem>
					{/* <NavItem>
						<NavLink
							className={classnames({
								active: activeTab === "2",
								"bg-white": activeTab === "2",
								clickable: true,
								"font-1_5": true,
								"rounded-top": true,
							})}
							onClick={() => {
								toggle("2");
							}}
						>
							My Orders
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({
								active: activeTab === "2",
								"bg-white": activeTab === "2",
								clickable: true,
								"font-1_5": true,
								"rounded-top": true,
							})}
							onClick={() => {
								toggle("3");
							}}
						>
							Producer
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({
								active: activeTab === "2",
								"bg-white": activeTab === "2",
								clickable: true,
								"font-1_5": true,
								"rounded-top": true,
							})}
							onClick={() => {
								toggle("4");
							}}
						>
							DJ
						</NavLink>
					</NavItem> */}
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1" className="fade show">
						<Row>
							<Col sm="12">
								<Profile user={user} />
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId="2" className="fade show">
						<Row>
							<Col sm="12">
								<h2>Your orders appear here</h2>
							</Col>
						</Row>
					</TabPane>
				</TabContent>
			</Col>
		</Row>
	);
};
