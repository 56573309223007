import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Header } from "src/components/layout/header";
import { RegisterView } from "src/containers/users/registerView";
import { SigninView } from "src/containers/users/signinView";
import { SignoutView } from "src/containers/users/signoutView";
import { UserContextProvider } from "./components/context/userContext";
import { ForgotPasswordView } from "./containers/users/forgotPasswordView";
import { ProfileView } from "./containers/users/profileView";
import { ResetPasswordView } from "./containers/users/resetPasswordView";

// import { Footer } from "src/components/layout/footer";

const App = () => {
	return (
		<>
			<div className="container-fluid px-0">
				<BrowserRouter>
					<UserContextProvider>
						<Header />

						<div className="container app-body">
							<div className="row">
								<div className="col">
									<Switch>
										<Route exact path="/" component={ProfileView} />
										<Route
											exact
											path="/profile"
											component={ProfileView}
										/>
										<Route
											exact
											path="/signin"
											component={SigninView}
										/>
										<Route
											exact
											path="/signout"
											component={SignoutView}
										/>
										<Route
											exact
											path="/register"
											component={RegisterView}
										/>
										<Route
											exact
											path="/register/:code"
											component={RegisterView}
										/>
										<Route
											exact
											path="/forgotpassword"
											component={ForgotPasswordView}
										/>
										<Route
											exact
											path="/resetpassword"
											component={ResetPasswordView}
										/>{" "}
									</Switch>
								</div>
							</div>
						</div>
					</UserContextProvider>

					{/* <Footer /> */}
				</BrowserRouter>
			</div>
		</>
	);
};

export default App;
