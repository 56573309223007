import { PasswordRegex } from "src/utils/constants";
import * as yup from "yup";

export const ResetPasswordFormSchema = yup
	.object()
	.shape({
		// code: yup.string().required().ensure().trim(),
		username: yup.string().email().required().max(50).ensure().trim(),
		password: yup
			.string()
			.required()
			.min(6)
			.matches(PasswordRegex, "Invalid password format")
			.ensure(),

		confirmPassword: yup
			.string()
			.oneOf([yup.ref("password")])
			.required()
			.ensure(),
	})
	.required();

export type IResetPasswordForm = yup.InferType<typeof ResetPasswordFormSchema>;

export const initialResetPasswordForm: IResetPasswordForm = {
	username: "",
	// code: "",
	password: "",
	confirmPassword: "",
};
