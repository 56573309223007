import React, { PropsWithChildren } from "react";
import StepWizard, { StepWizardChildProps } from "react-step-wizard";

interface IProps {
   titles: string[];
}

type RegisterNavProps = Partial<StepWizardChildProps> & {
   titles: string[];
};

export const RegisterNav: React.FC<RegisterNavProps> = ({
   titles,
   ...props
}) => {
   const navUnits = [];
   const navSteps = props.totalSteps || navUnits.length;

   const handleNavClick = (i: number) => {
      if (props.goToStep) {
         props.goToStep(i);
      }
   };
   for (let i = 1; i <= navSteps; i += 1) {
      const isActive = props.currentStep === i;
      navUnits.push(
         <span
            key={`step-${i}`}
            className={`nav-unit animate__animated ${
               isActive ? "active animate__bounce" : "text-muted"
            }`}
            onClick={(e) => handleNavClick(i)}
         >
            <h4>{`${String(i).padStart(2, "0")}.`}</h4>
            <h6 className={"ml-h font-weight-bold"}>{titles[i - 1]}</h6>
         </span>
      );
   }

   return (
      <>
         <div className={"register-nav"}>{navUnits}</div>
         <hr className={"mb-3"} />
      </>
   );
};

export const Wizard = (props: PropsWithChildren<IProps>) => {
   const childElements =
      React.Children.map(props.children, (x) => x as JSX.Element) || [];

   const animation = {
      enterRight: "animate__animated animate__fadeIn",
      enterLeft: "animate__animated animate__fadeIn",
      exitRight: "animate__animated animate__fadeOut",
      exitLeft: "animate__animated animate__fadeOut",
   };

   return (
      <div className="row">
         <div className="col bu-wizard">
            <StepWizard
               nav={<RegisterNav titles={props.titles} />}
               children={childElements}
               transitions={animation}
            />
         </div>
      </div>
   );
};
