import qs from "qs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserDto } from "src/api/models/dto/users/userDto";
import { UserService } from "src/api/services/userService";

export const useCurrentUser = (): UserDto | null => {
	const [user, setUser] = useState<UserDto | null>(null);

	useEffect(() => {
		async function workerAsync() {
			const currentUser = await UserService.getCurrentUserAsync();
			setUser(currentUser);
		}

		workerAsync();
	}, []);

	return user;
};

export function useQueryString<TParams>(): TParams {
	const routeParams = useParams<TParams>();
	const queryString = qs.parse(window.location.search.substring(1)) as any;

	if (routeParams && !queryString) {
		return routeParams;
	} else if (queryString && !routeParams) {
		return queryString as TParams;
	}

	return { ...routeParams, ...(queryString as TParams) };
}
