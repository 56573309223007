import React, { useCallback, useEffect } from "react";
import { CenterScreen } from "src/components/layout/centerScreen";

interface IProps {
	signoutAsync: () => Promise<any>;
}

export const Signout = (props: IProps) => {
	const { signoutAsync } = props;

	const signoutAsyncCallback = useCallback(async () => await signoutAsync(), [
		signoutAsync,
	]);

	useEffect(() => {
		signoutAsyncCallback();
	}, [signoutAsyncCallback]);

	return (
		<CenterScreen
			title="Sign Out"
			md={{ size: 10, offset: 1 }}
			lg={{ size: 6, offset: 3 }}
		>
			<div className="pt-4 text-center">
				<i className="fas fa-spinner fa-pulse"></i>
				&nbsp;Please wait while we sign you out!
			</div>
		</CenterScreen>
	);
};
