import * as QueryString from "query-string";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { AccountService } from "src/api/services/accountService";
import { Signout } from "src/components/user/signout";
import appConfig from "src/utils/appConfig";

interface IProps extends RouteComponentProps {}

export const SignoutView = (props: IProps) => {
	let logoutId = "";
	const history = useHistory();
	const params = QueryString.parse(props.location.search);
	for (const key in params) {
		if (
			Object.prototype.hasOwnProperty.call(params, key) &&
			key.toLocaleLowerCase() === "logoutId".toLocaleLowerCase()
		) {
			logoutId = params[key]?.toString() ?? "";
		}
	}

	if ((logoutId || "").trim() === "") {
		history.push(appConfig.domains.webReact);
		return <></>;
	}

	const logUserOutAsync = async () => {
		if (logoutId && logoutId.trim() !== "") {
			return await AccountService.signoutAsync(logoutId);
		}
	};

	return (
		<div className="center-screen">
			<Signout signoutAsync={logUserOutAsync} />
		</div>
	);
};
