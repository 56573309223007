import React from "react";
import { ResetPassword } from "src/components/user/forgotPassword/resetPassword";
import { useQueryString } from "src/utils/hooks";

interface IRouteParams {
	email: string;
	code: string;
}

export const ResetPasswordView = () => {
	const routeParams = useQueryString<IRouteParams>();

	return (
		<>
			<ResetPassword email={routeParams.email} code={routeParams.code} />
		</>
	);
};
