import { Field, FieldProps, Form, Formik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { AccountService } from "src/api/services/accountService";
import AjaxButton from "src/components/common/ajaxButton";
import { CenterScreen } from "src/components/layout/centerScreen";
import {
	ISigninForm,
	SigninFormSchema,
	initialSigninForm,
} from "src/models/Forms/signin";
import { nameof } from "ts-simple-nameof";
import { FormikWrapper } from "../common/formik/formikWrapper";

interface IProps {
	returnUrl: string;
}

export const Signin = (props: IProps) => {
	const [showError, setShowError] = useState(false);
	const [ajaxButtonLoading, setAjaxButtonLoading] = useState(false);

	const handleSubmitAsync = async (form: ISigninForm): Promise<any> => {
		setShowError(false);
		setAjaxButtonLoading(true);
		form.returnUrl = props.returnUrl;

		const response = await AccountService.signinAsync(form);
		console.log(response);

		if (response === null) {
			setAjaxButtonLoading(false);
			setShowError(true);
		} else {
			window.location.href = response.data;
		}
	};

	return (
		<CenterScreen
			title="Sign In"
			md={{ size: 10, offset: 1 }}
			lg={{ size: 6, offset: 3 }}
		>
			<div className="form-group pt-1">
				<FormikWrapper>
					<Formik<ISigninForm>
						initialValues={initialSigninForm}
						validationSchema={SigninFormSchema}
						onSubmit={async (values /*, actions*/) => {
							await handleSubmitAsync(values);
						}}
					>
						{(formikProps) => (
							<Form>
								<Field name={nameof<ISigninForm>((s) => s.username)}>
									{(renderArgs: FieldProps<any>) => {
										const { field, meta } = renderArgs;
										return (
											<div className="form-outline mt-1">
												<input
													type="text"
													className={
														meta.touched && meta.error
															? "form-control is-invalid"
															: "form-control"
													}
													id="textboxUsername"
													{...field}
												/>
												<label
													className="form-label"
													htmlFor="textboxUsername"
												>
													Username
												</label>
											</div>
										);
									}}
								</Field>
								<Field name={nameof<ISigninForm>((s) => s.password)}>
									{(renderArgs: FieldProps<any>) => {
										const { field, meta } = renderArgs;
										return (
											<div className="form-outline mt-1">
												<input
													type="password"
													className={
														meta.touched && meta.error
															? "form-control is-invalid"
															: "form-control"
													}
													id="textboxPassword"
													{...field}
												/>
												<label
													className="form-label"
													htmlFor="textboxPassword"
												>
													Password
												</label>
											</div>
										);
									}}
								</Field>

								<Row className="align-items-center mt-1">
									<Col xs="12" md="6">
										<Field
											name={nameof<ISigninForm>((s) => s.rememberMe)}
										>
											{(renderArgs: FieldProps<any>) => {
												const { field } = renderArgs;
												return (
													<div className="form-check">
														<input
															className="form-check-input"
															type="checkbox"
															id="checkRememberMe"
															{...field}
														/>
														<label
															className="form-check-label"
															htmlFor="checkRememberMe"
														>
															Remember Me
														</label>
													</div>
												);
											}}
										</Field>
									</Col>
									<Col
										xs="12"
										md="6"
										className="mt-1 mt-md-0 text-md-right"
									>
										<div className="text-end flex-grow-0">
											<Link to="/forgotpassword">
												Forgot Password?
											</Link>
										</div>
									</Col>
								</Row>

								{/* <div className="d-flex align-items-center mt-1">
									<div className="flex-grow-1">

									</div>


								</div> */}

								<div className="pt-2">
									<div className="row">
										<div className="col-xs-12 col-md-6">
											<AjaxButton
												type="submit"
												className="btn btn-lg btn-primary"
												loading={ajaxButtonLoading}
											>
												Sign In
											</AjaxButton>
										</div>
										<div className="col-xs-12 col-md-6 mt-s-1">
											<Link
												to="/register"
												className="btn btn-lg ripple-surface w-100  text-no-wrap text-black1 text-muted1"
											>
												Register
											</Link>
										</div>
									</div>
								</div>
								<div className="pt-1">
									{showError && (
										<Row>
											<Col>
												<ListGroup>
													<ListGroupItem color="danger">
														Sign In failed. Please try again
														later.
													</ListGroupItem>
												</ListGroup>
											</Col>
										</Row>
									)}
								</div>
							</Form>
						)}
					</Formik>
				</FormikWrapper>
				{/* <div className="pt-2 line-and-text">
										<span className="text-muted">OR</span>
									</div>

									<div className="pt-1">
										<button
											type="button"
											className="btn btn-lg bg-white w-100 text-transform-none"
										>
											<i className="fab fa-google text-google"></i>
											&nbsp;&nbsp;Continue with Google
										</button>
									</div>
									<div className="pt-1">
										<button
											type="button"
											className="btn btn-lg bg-white w-100 text-transform-none"
										>
											<i className="fab fa-microsoft text-microsoft"></i>
											&nbsp;&nbsp;Continue with Microsoft
										</button>
									</div> */}
				{/* <div className="pt-1">
                              <button
                                 type="button"
                                 className="btn btn-lg bg-white w-100 text-transform-none"
                              >
                                 <i className="fab fa-facebook-f text-facebook"></i>
                                 &nbsp;&nbsp;Continue with Facebook
                              </button>
                           </div> */}
			</div>
		</CenterScreen>
	);
};
