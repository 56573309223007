import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import ReactDOM from "react-dom";
import appConfig from "src/utils/appConfig";
import { reactPlugin } from "src/utils/logging";
import App from "./App";
import "./app.scss";
import reportWebVitals from "./reportWebVitals";

console.log("X-BU-Env:", appConfig.environments.name);

ReactDOM.render(
	<AppInsightsContext.Provider value={reactPlugin}>
		<div className="app-wrapper">
			<App />
		</div>
	</AppInsightsContext.Provider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
