import { Form, Formik } from "formik";
import React, { SetStateAction, useContext, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, Table } from "reactstrap";
import { UserService } from "src/api/services/userService";
import { FormikGooglePlace } from "src/components/common/formik/formikGooglePlace";
import {
	FormikTextbox,
	InputTypes,
} from "src/components/common/formik/formikTextbox";
import { FormikWrapper } from "src/components/common/formik/formikWrapper";
import { SaveButtonTypes, SaveCancel } from "src/components/common/saveCancel";
import { UserContext } from "src/components/context/userContext";
import {
	IPersonalInfoForm,
	PersonalInfoFormSchema,
} from "src/models/Forms/registration/personalInfo";
import { nameof } from "ts-simple-nameof";
import { IProfileComponentProps } from "./profile";

interface IProps extends IProfileComponentProps {
	setEditMode: React.Dispatch<SetStateAction<boolean>>;
}

/* ------------------------------------------------------  */
const PersonalInfoEditor = (props: IProps) => {
	const initialPersonalInfo: IPersonalInfoForm = {
		firstName: props.user.firstName,
		lastName: props.user.lastName,
		location: props.user.location,
		phoneNo: props.user.phoneNo,
	};

	const userContext = useContext(UserContext);

	/* ------------------------------------------------------  */
	const saveAsync = async (values: IPersonalInfoForm) => {
		const user = await UserService.savePersonalInfoAsync(values);
		userContext?.updateUser(user);
		props.setEditMode(false);
		return Promise.resolve();
	};

	const cancelAsync = async (props: IProps) => {
		props.setEditMode(false);
		return Promise.resolve();
	};

	return (
		<FormikWrapper>
			<Formik<IPersonalInfoForm>
				initialValues={initialPersonalInfo}
				validationSchema={PersonalInfoFormSchema}
				onSubmit={async (values /*, actions*/) => {
					await saveAsync(values);
				}}
			>
				{(formikProps) => (
					<Form>
						<Card>
							<CardHeader>
								<div className="d-flex align-items-center">
									<div className="flex-grow-1 py-h">Personal Info</div>
								</div>
							</CardHeader>
							<CardBody>
								<Table borderless={true} className="align-middle">
									<tbody>
										<tr>
											<td>First Name</td>
											<td>
												<FormikTextbox
													type={InputTypes.text}
													name={nameof<IPersonalInfoForm>(
														(s) => s.firstName
													)}
												/>
											</td>
										</tr>
										<tr>
											<td>Last Name</td>
											<td>
												<FormikTextbox
													type={InputTypes.text}
													name={nameof<IPersonalInfoForm>(
														(s) => s.lastName
													)}
												/>
											</td>
										</tr>
										<tr>
											<td>Phone No</td>
											<td>
												<FormikTextbox
													type={InputTypes.phone}
													name={nameof<IPersonalInfoForm>(
														(s) => s.phoneNo
													)}
												/>
											</td>
										</tr>
										<tr>
											<td>City/Country</td>
											<td>
												<FormikGooglePlace
													fieldName={nameof<IPersonalInfoForm>(
														(s) => s.location
													)}
													// setFieldValue={formikProps.setFieldValue}
												/>
											</td>
										</tr>
									</tbody>
								</Table>
							</CardBody>
							<CardFooter>
								<div className="d-flex align-items-center">
									<div className="flex-grow-1 text-end">
										<SaveCancel
											saveButtonType={SaveButtonTypes.Submit}
											onCancelAsync={() => cancelAsync(props)}
										/>
									</div>
								</div>
							</CardFooter>
						</Card>
					</Form>
				)}
			</Formik>
		</FormikWrapper>
	);
};

/* ------------------------------------------------------  */
const PersonalInfoDisplay = (props: IProps) => {
	return (
		<Card>
			<CardHeader>
				<div className="d-flex align-items-center">
					<div className="flex-grow-1 py-h">Personal Info</div>
					<div className="flex-grow-1 text-end">
						<button
							type="button"
							className="btn btn-link"
							data-ripple-color="dark"
							onClick={() => {
								props.setEditMode && props.setEditMode(true);
							}}
						>
							Edit
						</button>
					</div>
				</div>
			</CardHeader>
			<CardBody>
				<Table borderless={true} className="align-middle">
					<tbody>
						<tr>
							<td>First Name</td>
							<td>{props.user.firstName}</td>
						</tr>
						<tr>
							<td>Last Name</td>
							<td>{props.user.lastName}</td>
						</tr>
						<tr>
							<td>Phone No</td>
							<td>{props.user.phoneNo}</td>
						</tr>
						<tr>
							<td>City/Country</td>
							<td>{props.user.location}</td>
						</tr>
					</tbody>
				</Table>
			</CardBody>
		</Card>
	);
};

/* ------------------------------------------------------  */
export const PersonalInfo = (props: IProfileComponentProps) => {
	const [isEditMode, setEditMode] = useState(false);
	const innerProps: IProps = {
		...props,
		setEditMode: setEditMode,
	};

	return (
		<div className="component-profile-personal">
			{isEditMode ? (
				<PersonalInfoEditor {...innerProps} />
			) : (
				<PersonalInfoDisplay {...innerProps} />
			)}
		</div>
	);
};
