import * as yup from "yup";

export const SigninFormSchema = yup
	.object({
		username: yup.string().required().max(50).ensure().trim(),

		password: yup.string().required().ensure(),

		rememberMe: yup.bool().default(false),

		returnUrl: yup.string(),
	})
	.required();

export type ISigninForm = yup.InferType<typeof SigninFormSchema>;

export const initialSigninForm: ISigninForm = {
	username: "",
	password: "",
	returnUrl: "",
	rememberMe: false,
};
