import { PasswordRegex } from "src/utils/constants";
import * as yup from "yup";

export const ProfilePasswordSchema = yup
	.object({
		currentPassword: yup.string().required().ensure(),

		newPassword: yup
			.string()
			.required()
			.min(6)
			.matches(PasswordRegex, "Invalid password format")
			.ensure(),

		confirmNewPassword: yup
			.string()
			.matches(PasswordRegex, "Invalid password format")
			.oneOf([yup.ref("newPassword")])
			.required()
			.ensure(),
	})
	.required();

export type IProfilePasswordForm = yup.InferType<typeof ProfilePasswordSchema>;

export const initialProfilePasswordForm: IProfilePasswordForm = {
	newPassword: "",
	currentPassword: "",
	confirmNewPassword: "",
};
