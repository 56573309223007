import axios, { AxiosResponse } from "axios";
import { ForgotPasswordRequest } from "src/api/models/requests/account/forgotPasswordRequest";
import { RegisterRequest } from "src/api/models/requests/account/registerRequest";
import { ResetPasswordRequest } from "src/api/models/requests/account/resetPasswordRequest";
import { CheckRegisterCodeResponse } from "src/api/models/responses/account/checkRegisterCodeResponse";
import { CheckUsernameResponse } from "src/api/models/responses/account/checkUsernameResponse";
import { RegisterResponse } from "src/api/models/responses/account/registerResponse";
import { IRegisterInfo } from "src/models/Forms/registration/registerInfo";
import { ISigninForm } from "src/models/Forms/signin";

export class AccountService {
	public static async signinAsync(
		signinForm: ISigninForm
	): Promise<AxiosResponse<string> | null> {
		const url = "/api/account/signin";
		try {
			const response = await axios.post<string>(url, signinForm, {
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				},
			});
			// if (response.data !== "") {
			// 	window.location.href = response.data;
			// 	return null;
			// }
			return response;
		} catch {
			return null;
		}
	}

	public static async signoutAsync(signoutId: string): Promise<any> {
		if (signoutId === "") {
			return;
		}

		const url = `/api/account/signout?logoutId=${signoutId}`;
		try {
			const response = await axios.post<string>(
				url,
				{},
				{
					headers: {
						"Content-Type": "application/json; charset=utf-8",
					},
				}
			);

			console.log("signoutAsync", response.data);
			window.location.href = response.data;

			return response;
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	public static async registerAsync(
		registerInfo: IRegisterInfo
	): Promise<any> {
		const url = `/api/account/register`;
		const registerRequest: RegisterRequest = {
			firstName: registerInfo.personalInfo.firstName,
			lastName: registerInfo.personalInfo.lastName,
			phoneNumber: registerInfo.personalInfo.phoneNo,
			location: registerInfo.personalInfo.location,

			username: registerInfo.accountInfo.username,
			email: registerInfo.accountInfo.email,
			password: registerInfo.accountInfo.password,
			confirmPassword: registerInfo.accountInfo.confirmPassword,
			code: registerInfo.code,
			isRandomPassword: false
		};

		try {
			const response = await axios.post<RegisterResponse>(
				url,
				registerRequest,
				{
					headers: {
						"Content-Type": "application/json; charset=utf-8",
					},
				}
			);
			return response;
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	public static async checkUsernameAsync(username: string): Promise<boolean> {
		if (username === "") {
			return false;
		}

		const encoded = encodeURI(username).replace("+", "%B2");
		const url = `/api/account/check/username/${encoded}`;
		try {
			const response = await axios.get<CheckUsernameResponse>(url);
			return response.data.exists;
		} catch (e) {
			return false;
		}
	}

	public static async forgotPasswordAsync(
		username: string,
		resetPageUrl: string
	): Promise<boolean> {
		if (username === "") {
			return false;
		}

		const request: ForgotPasswordRequest = {
			email: username,
			resetPageUrl: resetPageUrl,
		};
		const url = `/api/account/forgotPassword`;
		try {
			await axios.post<string>(url, request);
			return true;
		} catch (e) {
			return false;
		}
	}

	public static async resetPasswordAsync(
		username: string,
		password: string,
		confirmPassword: string,
		code: string
	): Promise<boolean> {
		if (username === "") {
			return false;
		}

		const request: ResetPasswordRequest = {
			email: username,
			password,
			confirmPassword,
			code,
		};
		const url = `/api/account/resetPassword`;
		try {
			await axios.post<string>(url, request);
			return true;
		} catch (e) {
			return false;
		}
	}

	public static async checkRegisterCodeAsync(
		code: string
	): Promise<CheckRegisterCodeResponse | null> {
		try {
			const url = `/api/account/check/registerCode/${code}`;
			const response = await axios.get<CheckRegisterCodeResponse>(url);

			return response.data;
		} catch (error) {
			console.log(error);
			return {
				exists: false,
				hasBeenUsed: false,
			};
		}
	}
}
