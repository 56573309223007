import factory, { IAppConfig } from "./_app.config";
const appConfig = factory();

export default {
	...appConfig,

	environments: {
		...appConfig.environments,
		isProduction: false,
		isSandbox: true,
		name: "Sandbox",
	},

	domains: {
		legacyWeb: "https://beatsunion-web-sandbox.azurewebsites.net",
		wordpress: "https://beatsunion.com",
		webReact: "https://beatsunion-react-sandbox.azurewebsites.net",
		fileServerUrl: "https://beatsunion-storage-sandbox.azurewebsites.net",
	},
	auth: {
		authority: "https://beatsunion-identity-sandbox.azurewebsites.net",
		client_id: "BeatsUnion.Web.React",
		response_type: "id_token token",
		scope: "openid profile offline_access api1.read",
		automaticSilentRenew: true,
		redirect_uri:
			"https://beatsunion-react-sandbox.azurewebsites.net/signin-oidc",
		silent_redirect_uri:
			"https://beatsunion-react-sandbox.azurewebsites.net/silent-signin-oidc",
		extraQueryParams: {
			signoutUrl:
				"https://beatsunion-react-sandbox.azurewebsites.net/signout-oidc",
		},
	},
} as IAppConfig;
