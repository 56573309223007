import {
	Field,
	FieldInputProps,
	FieldMetaProps,
	FieldProps,
	isFunction,
} from "formik";
import React from "react";

export enum InputTypes {
	text = "text",
	password = "password",
	email = "email",
	phone = "tel",
	number = "number",
}

interface IProps {
	name: string;
	label?: string;
	disabled?: boolean;
	type: InputTypes;
	preventAutoComplete?: boolean;
	showError?: boolean | ((meta: FieldMetaProps<any>) => boolean);

	onBlur?: (
		e: React.FocusEvent<HTMLInputElement>,
		field: FieldInputProps<any>,
		meta: FieldMetaProps<any>
	) => boolean;
	onFocus?: (
		e: React.FocusEvent<HTMLInputElement>,
		field: FieldInputProps<any>,
		meta: FieldMetaProps<any>
	) => void;
}

export const FormikTextbox = (props: IProps) => {
	const { name, label, type } = props;
	const inputName = `bu_textbox_${encodeURI(name)}`;

	const disabled = props.disabled ?? false;

	const togglePassword = (
		e: React.MouseEvent<HTMLSpanElement, MouseEvent>
	) => {
		const span = e.target as HTMLSpanElement;
		const textbox = document.querySelector(
			"#" + inputName
		) as HTMLInputElement;
		const attr = textbox?.attributes.getNamedItem("type");
		if (!attr) {
			return;
		}

		if (textbox?.value === "") {
			return;
		}

		if (attr.value === "password") {
			textbox?.setAttribute("type", "text");
			span.classList.add("fa-eye-slash");
			span.classList.remove("fa-eye");
		} else {
			textbox?.setAttribute("type", "password");
			span.classList.add("fa-eye");
			span.classList.remove("fa-eye-slash");
		}
	};

	const ShowPassword = () => {
		return type === InputTypes.password ? (
			<span
				className="fa fa-fw fa-eye text-muted field-icon clickable"
				onClick={(e) => togglePassword(e)}
			></span>
		) : (
			<></>
		);
	};

	interface IRenderErrorProps {
		hasError: boolean;
		showError: boolean;
		meta: FieldMetaProps<any>;
	}

	const RenderError = (props: IRenderErrorProps) => {
		return !props.hasError || !props.showError ? (
			<></>
		) : (
			<span className="field-icon form-error text-muted">
				{props.meta.error}
			</span>
		);
	};

	const handleOnBlur = (
		field: FieldInputProps<any>,
		meta: FieldMetaProps<any>,
		e: React.FocusEvent<HTMLInputElement>
	) => {
		let invokeFieldOnBlur = true;
		if (props.onBlur) {
			invokeFieldOnBlur = props.onBlur(e, field, meta) ?? true;
		}

		if (invokeFieldOnBlur) {
			field.onBlur(e);
		}
	};

	const handleOnFocus = (
		field: FieldInputProps<any>,
		meta: FieldMetaProps<any>,
		e: React.FocusEvent<HTMLInputElement>
	) => {
		props.preventAutoComplete && e.target.setAttribute("autocomplete", "off");
		props.type === InputTypes.password &&
			e.target.setAttribute("autocomplete", "off");
		props.onFocus && props.onFocus(e, field, meta);
	};

	return (
		<Field name={name}>
			{(renderArgs: FieldProps<any>) => {
				const { field, meta } = renderArgs;
				const hasError = meta.touched && (meta.error || "") !== "";

				const showError = isFunction(props.showError)
					? props.showError(meta)
					: props.showError ?? false;

				return (
					<>
						<div className="form-outline">
							<input
								key={inputName}
								id={inputName}
								type={type}
								disabled={disabled}
								className={
									hasError
										? "form-control is-invalid active"
										: "form-control"
								}
								{...field}
								onBlur={(e) => handleOnBlur(field, meta, e)}
								onFocus={(e) => handleOnFocus(field, meta, e)}
							/>
							{label && label !== "" && (
								<label className="form-label" htmlFor={inputName}>
									<>{label}</>
								</label>
							)}
							<ShowPassword />
							<RenderError
								meta={meta}
								hasError={hasError}
								showError={showError}
							/>
						</div>
					</>
				);
			}}
		</Field>
	);
};
