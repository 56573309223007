import { AccountService } from "src/api/services/accountService";
import { PasswordRegex } from "src/utils/constants";
import * as yup from "yup";
import "yup-phone";

export const UsernameExistsErrorMessage = "Username Exists";

export const GetAccountInfoFormSchema = (validateUsername: boolean) => {
	return yup
		.object()
		.shape({
			username: yup
				.string()
				.required()
				.test("checkUsernameExists", "Username Exists", async (value) => {
					if (!validateUsername) {
						return Promise.resolve(true);
					}

					const response = await AccountService.checkUsernameAsync(
						value || ""
					);
					return Promise.resolve(!response);
				})
				.email()
				.min(5)
				.max(50)
				.ensure()
				.trim(),

			email: yup
				.string()
				.required()
				.email()
				.ensure()
				.trim()
				.oneOf([yup.ref("username")]),

			password: yup
				.string()
				.required()
				.min(6)
				.matches(PasswordRegex, "Invalid password format")
				.ensure(),

			confirmPassword: yup
				.string()
				.oneOf([yup.ref("password")])
				.required()
				.ensure(),

			terms: yup.boolean().oneOf([true]),
		})
		.required();
};

export const AccountInfoFormShape = GetAccountInfoFormSchema(false);
export type IAccountInfoForm = yup.InferType<typeof AccountInfoFormShape>;
export const initialAccountInfoForm: IAccountInfoForm = {
	username: "",
	email: "",
	password: "",
	confirmPassword: "",
	terms: false as any,
};
