import { Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Form, ListGroup, ListGroupItem, Row } from "reactstrap";
import { AccountService } from "src/api/services/accountService";
import AjaxButton from "src/components/common/ajaxButton";
import {
	FormikTextbox,
	InputTypes,
} from "src/components/common/formik/formikTextbox";
import { FormikWrapper } from "src/components/common/formik/formikWrapper";
import { CenterScreen } from "src/components/layout/centerScreen";
import {
	ForgotPasswordFormSchema,
	IForgotPasswordForm,
	initialForgotPasswordForm,
} from "src/models/Forms/forgotPassword/forgotPassword";
import { nameof } from "ts-simple-nameof";

interface IProps {
	handleSubmitAsync: (form: IForgotPasswordForm) => Promise<any>;
	ajaxButtonLoading: boolean;
	showError: boolean;
}

const RenderConfirmation = () => {
	return (
		<>
			<div className="pt-2">
				Your request has been submitted.
				<br />
				If the provided email matches our records, you will recieve an email
				with instructions on how to reset your password.
			</div>
			<div className="form-group pt-2">
				<Row>
					<Col xs="12" md="6">
						<Link to="/signin" className="btn btn-primary btn-lg w-100">
							Sign In
						</Link>
					</Col>
				</Row>
			</div>
		</>
	);
};

const RenderForm = (props: IProps) => {
	return (
		<>
			<div className="pt-2">
				Please enter your email address to continue.
			</div>
			<div className="form-group pt-1">
				<FormikWrapper>
					<Formik<IForgotPasswordForm>
						initialValues={initialForgotPasswordForm}
						validationSchema={ForgotPasswordFormSchema}
						onSubmit={async (values /*, actions*/) => {
							await props.handleSubmitAsync(values);
						}}
					>
						{(formikProps) => (
							<Form>
								<FormikTextbox
									type={InputTypes.text}
									label="Email Address"
									name={nameof<IForgotPasswordForm>((f) => f.username)}
								/>
								<div className="pt-2">
									<Row>
										<Col xs="12" md="6">
											<AjaxButton
												type="button"
												onClick={formikProps.submitForm}
												className="btn btn-lg btn-primary"
												loading={props.ajaxButtonLoading}
											>
												Submit
											</AjaxButton>
										</Col>
									</Row>
								</div>
								<div className="pt-1">
									{props.showError && (
										<Row>
											<Col>
												<ListGroup>
													<ListGroupItem color="danger">
														There was an error when submitting
														your request. Please try again later.
													</ListGroupItem>
												</ListGroup>
											</Col>
										</Row>
									)}
								</div>
							</Form>
						)}
					</Formik>
				</FormikWrapper>
			</div>
		</>
	);
};

export const ForgotPassword = () => {
	const [isFormMode, setIsFormMode] = useState(true);
	const [showError, setShowError] = useState(false);
	const [ajaxButtonLoading, setAjaxButtonLoading] = useState(false);

	const handleSubmitAsync = async (
		form: IForgotPasswordForm
	): Promise<any> => {
		setShowError(false);
		setAjaxButtonLoading(true);

		const resetPageUrl = `${window.location.origin}/resetpassword`;
		const isSuccess = await AccountService.forgotPasswordAsync(
			form.username,
			resetPageUrl
		);

		if (isSuccess) {
			setTimeout(() => {
				setIsFormMode(false);
				setAjaxButtonLoading(false);
			}, 1000);
		} else {
			setShowError(true);
		}
	};

	return (
		<>
			<CenterScreen
				title="Forgot Password"
				md={{ size: 10, offset: 1 }}
				lg={{ size: 6, offset: 3 }}
			>
				{isFormMode ? (
					<RenderForm
						handleSubmitAsync={handleSubmitAsync}
						showError={showError}
						ajaxButtonLoading={ajaxButtonLoading}
					/>
				) : (
					<RenderConfirmation />
				)}
			</CenterScreen>
		</>
	);
};
