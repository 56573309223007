import { Form, Formik } from "formik";
import React, { useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import AjaxButton from "src/components/common/ajaxButton";
import { FormikCheckbox } from "src/components/common/formik/formikCheckbox";
import {
	FormikTextbox,
	InputTypes,
} from "src/components/common/formik/formikTextbox";
import { FormikWrapper } from "src/components/common/formik/formikWrapper";
import {
	GetAccountInfoFormSchema,
	IAccountInfoForm,
	initialAccountInfoForm,
	UsernameExistsErrorMessage,
} from "src/models/Forms/registration/accountInfo";
import { nameof } from "ts-simple-nameof";

type IProps = Partial<StepWizardChildProps> & {
	onSubmitedAsync: (values: IAccountInfoForm) => Promise<void>;
	showError: boolean;
	showSuccess: boolean;
	inProgress: boolean;
};

const agreeTerms = () => {
	return (
		<>
			I agree to and have read the{" "}
			<a
				href="https://beatsunion.com/TermsOfUse"
				target="_blank"
				rel="noreferrer"
			>
				Terms of Service
			</a>{" "}
			and{" "}
			<a
				href="https://beatsunion.com/PrivacyPolicy"
				target="_blank"
				rel="noreferrer"
			>
				Privacy Policy
			</a>
			.
		</>
	);
};

export const AccountInfo = (props: IProps) => {
	const [state, setState] = useState({
		schema: GetAccountInfoFormSchema(true),
	});

	const { schema } = state;
	const { showSuccess, showError, inProgress } = props;

	const handleSubmitAsync = async (values: IAccountInfoForm) => {
		await props.onSubmitedAsync(values);
	};

	const handleBackClicked = () => {
		props?.previousStep && props?.previousStep();
	};

	return (
		<>
			<h4 className="mb-2">
				<strong>Account Info</strong>
			</h4>
			<FormikWrapper>
				<Formik<IAccountInfoForm>
					initialValues={initialAccountInfoForm}
					validationSchema={schema}
					onSubmit={async (values /*, actions*/) => {
						await handleSubmitAsync(values);
					}}
				>
					{(formikProps) => (
						<Form>
							<div className="row">
								<div className="col-12 col-md-6 mb-1">
									<FormikTextbox
										label="Email Address"
										showError={(meta) =>
											meta.error === UsernameExistsErrorMessage
										}
										type={InputTypes.email}
										name={nameof<IAccountInfoForm>((s) => s.username)}
										onFocus={(e, field, meta) => {
											setState({
												...state,
												schema: GetAccountInfoFormSchema(true),
											});
										}}
										onBlur={(e, field, meta) => {
											setState({
												...state,
												schema: GetAccountInfoFormSchema(false),
											});
											return true;
										}}
									/>
								</div>
								<div className="col-12 col-md-6 mb-1">
									<FormikTextbox
										label="Confirm Email Address"
										type={InputTypes.email}
										name={nameof<IAccountInfoForm>((s) => s.email)}
									/>
								</div>
								<div className="col-12 col-md-6 mb-1 mb-lg-0">
									<FormikTextbox
										label="Password"
										type={InputTypes.password}
										name={nameof<IAccountInfoForm>((s) => s.password)}
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormikTextbox
										label="Confirm Password"
										type={InputTypes.password}
										name={nameof<IAccountInfoForm>(
											(s) => s.confirmPassword
										)}
									/>
								</div>
							</div>
							<div className="row mb-1">
								<div className="col text-muted font-sm">
									Min 6 Characters: an Upper, a Lower, a Number, and a
									Special character
								</div>
							</div>
							<div className="row mt-1">
								<div className="col">
									<FormikCheckbox
										label={agreeTerms()}
										name={nameof<IAccountInfoForm>((s) => s.terms)}
									/>
								</div>
							</div>
							<Row className="mt-2">
								<Col>
									<AjaxButton
										type="submit"
										width100={false}
										className="btn btn-lg btn-primary"
										loading={inProgress}
									>
										Create Account
									</AjaxButton>

									<button
										type="button"
										className="btn btn-lg ml-1"
										onClick={handleBackClicked}
									>
										Back
									</button>
								</Col>{" "}
							</Row>
							{showError && (
								<Row className="mt-1">
									<Col>
										<ListGroup>
											<ListGroupItem color="danger">
												Error creating a new user. Please try again
												later.
											</ListGroupItem>
										</ListGroup>
									</Col>
								</Row>
							)}
							{showSuccess && (
								<Row className="mt-1">
									<Col>
										<ListGroup>
											<ListGroupItem color="success">
												Account created successfully.
											</ListGroupItem>
										</ListGroup>
									</Col>
								</Row>
							)}
						</Form>
					)}
				</Formik>
			</FormikWrapper>
		</>
	);
};
