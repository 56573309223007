import React, {
	createContext,
	PropsWithChildren,
	useEffect,
	useState,
} from "react";
import { UserDto } from "src/api/models/dto/users/userDto";
import { UserService } from "src/api/services/userService";

interface IUserContext {
	user: UserDto | null;
	updateUser: (user: UserDto | null) => void;
}

interface IProviderProps {}

export const UserContext = createContext<IUserContext | null>(null);

export const UserContextProvider = (
	props: PropsWithChildren<IProviderProps>
) => {
	const [state, setState] = useState<UserDto | null>(null);

	const updateUser = (user: UserDto | null): void => {
		setState(user);
	};

	useEffect(() => {
		async function workerAsync() {
			const currentUser = await UserService.getCurrentUserAsync();
			updateUser(currentUser);
		}
		workerAsync();
	}, []);

	return (
		<>
			<UserContext.Provider
				value={{
					user: state,
					updateUser,
				}}
			>
				{props.children}
			</UserContext.Provider>
		</>
	);
};
