import { ReactElement, useContext } from "react";
import { UserDto } from "src/api/models/dto/users/userDto";
import { UserContext } from "src/components/context/userContext";

interface IProps {
	loggedInTemplate: (user: UserDto) => ReactElement;
	loggedOutTemplate: () => ReactElement;
}

export const AuthProvider = (props: IProps) => {
	const { loggedInTemplate, loggedOutTemplate } = props;

	const userContext = useContext(UserContext);

	if (!userContext?.user) {
		return loggedOutTemplate();
	}
	const { user } = userContext;

	return loggedInTemplate(user);
};
