import React, { useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { Link } from "react-router-dom";
import {
	Collapse,
	Container,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
} from "reactstrap";
import { UserDto } from "src/api/models/dto/users/userDto";
import { AuthProvider } from "src/components/user/authProvider";
import appConfig from "src/utils/appConfig";
import { createNewGuid } from "src/utils/strings";

interface IProps {}

interface IAuthTemplateProps {
	user: UserDto;
	isOpen: boolean;
	toggle: () => void;
	close: () => void;
}

const SignedInTemplate = (props: IAuthTemplateProps) => {
	const ref = useOnclickOutside(() => {
		props.close();
	});

	return (
		<>
			<NavLink className="text-primary clickable" onClick={props.toggle}>
				{props.user.firstName}
			</NavLink>
			<div
				ref={ref}
				className={`dropdown-menu dropdown-menu-navbar animated ${
					props.isOpen ? "fadeIn show" : "fadeOut"
				}`}
			>
				<Link className="dropdown-item" to={`/profile`}>
					Profile
				</Link>
				<a
					className="dropdown-item"
					href={`${appConfig.domains.webReact}/MyOrders`}
				>
					My Orders
				</a>

				<Link
					className="dropdown-item"
					to={`/signout?logoutId=${createNewGuid()}`}
				>
					Sign out
				</Link>
			</div>
		</>
	);
};

const SignedOutTemplate = () => {
	return (
		<Link className="dropdown-item text-primary" to={`/signin`}>
			Sign In
		</Link>
	);
};

export const Header = (props: IProps) => {
	const [isNavbarOpen, setIsNavbarOpen] = useState(false);
	const toggleNavbar = () => {
		setIsNavbarOpen(!isNavbarOpen);
	};

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};
	const closeDropdown = () => {
		setIsDropdownOpen(false);
	};

	return (
		<header>
			<Navbar color="light" light expand="md" className="fixed-top">
				<Container className="align-items-center">
					<NavbarBrand href={`${appConfig.domains.wordpress}`}>
						<img
							className="navbar-logo"
							alt="BeatsUnion"
							src="/assets/beats_union_hz_blue.svg"
						/>
					</NavbarBrand>
					<NavbarToggler onClick={toggleNavbar}>
						<i className="fa fa-bars" />
					</NavbarToggler>
					<Collapse
						className="d-md-inline-flex flex-md-row-reverse"
						isOpen={isNavbarOpen}
						navbar
					>
						<ul className="navbar-nav flex-grow">
							<NavItem>
								<NavLink
									href={`${appConfig.domains.webReact}`}
									className="text-primary"
								>
									Explore Music
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className="text-primary"
									href={`${appConfig.domains.legacyWeb}/account/login?returnUrl=%2FExplorer`}
								>
									Artist Portal
								</NavLink>
							</NavItem>
							<NavItem className="position-relative">
								<AuthProvider
									loggedInTemplate={(user: UserDto) => (
										<SignedInTemplate
											user={user}
											isOpen={isDropdownOpen}
											toggle={toggleDropdown}
											close={closeDropdown}
										/>
									)}
									loggedOutTemplate={() => <SignedOutTemplate />}
								/>
							</NavItem>
						</ul>
					</Collapse>
				</Container>
			</Navbar>
		</header>
	);
};
