import React from "react";

interface IProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	loading: boolean;
	width100?: boolean;
	loadingText?: string;
}

const AjaxButton = (props: IProps) => {
	const {
		width100,
		className,
		loading,
		loadingText,
		...propsNoLoading
	} = props;
	const addWidth100 = width100 ?? true;

	return (
		<div
			className={`ajax-button ${addWidth100 ? "w-100" : ""} ${
				loading ? " loading" : ""
			}`}
		>
			<button
				disabled={loading}
				{...propsNoLoading}
				className={`${className} w-100`}
			>
				{props.children}
			</button>
			<div
				className={`loading-overlay align-items-center justify-content-center animated faster ${className} ${
					loading ? "d-flex fadeIn" : "hidden fadeOut"
				}`}
			>
				<i className="fa fa-spin fa-sync-alt" />
				&nbsp;{loadingText || ""}
			</div>
		</div>
	);
};

export default AjaxButton;
