import { asUploadButton } from "@rpldy/upload-button";
import Uploady, {
	BatchItem,
	useItemFinishListener,
	useItemProgressListener,
	useItemStartListener,
} from "@rpldy/uploady";
import React, { forwardRef } from "react";
import { Button } from "reactstrap";
import appConfig from "src/utils/appConfig";

export type UploadItem = BatchItem;

interface IProps {
	text: string;

	onUploadStarted?: (item: UploadItem) => void;
	onUploadProgress?: (item: UploadItem) => void;
	onUploadCompleted?: (uploadUrl: string, item: UploadItem) => void;
}

const FileFilter = (
	uploadedFile: unknown,
	index: number,
	uploadedFiles: unknown[]
): boolean => {
	const file = uploadedFile as File;
	const files = uploadedFiles as File[];

	console.log(file.name);
	console.log(file.type);
	console.log(file.size);
	console.log(files.length);
	return true;
};

const UploadButton = asUploadButton(
	forwardRef((props: any, ref: any) => {
		return (
			<>
				<Button {...props} type="button" color="primary">
					{props.children}
				</Button>
			</>
		);
	})
);

const UploadyContextHelper = (props: IProps) => {
	useItemStartListener((item: UploadItem) => {
		props.onUploadStarted && props.onUploadStarted(item);
	});

	useItemProgressListener((item: UploadItem) => {
		props.onUploadProgress && props.onUploadProgress(item);
	});

	useItemFinishListener((item: UploadItem) => {
		const uploadUrl = item?.uploadResponse?.data || "";
		props.onUploadCompleted && props.onUploadCompleted(uploadUrl, item);
	});

	return <></>;
};

export const FileUploader = (props: IProps) => {
	return (
		<>
			<Uploady
				destination={{
					url: `${appConfig.domains.fileServerUrl}/home/UploadReact`,
				}}
				inputFieldName="files"
				forceJsonResponse={true}
				multiple={false}
			>
				<UploadyContextHelper {...props} />
				<UploadButton text={props.text} fileFilter={FileFilter} />
			</Uploady>
		</>
	);
};
