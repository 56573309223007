import axios from "axios";
import { UserDto } from "src/api/models/dto/users/userDto";
import { AccountInfoRequest } from "src/api/models/requests/users/accountInfoRequest";
import { PersonalInfoRequest } from "src/api/models/requests/users/personalInfoRequest";
import { ProfilePictureRequest } from "src/api/models/requests/users/profilePictureRequest";
import { IProfilePasswordForm } from "src/models/Forms/profile/profilePassword";
import { IPersonalInfoForm } from "src/models/Forms/registration/personalInfo";

export class UserService {
	public static async getCurrentUserAsync(): Promise<UserDto | null> {
		try {
			const url = "/api/user/currentuser";
			const response = await axios.get<UserDto>(url);
			if (response.status === 200) {
				return response.data;
			}
		} catch {}

		return null;
	}

	public static async savePersonalInfoAsync(
		data: IPersonalInfoForm
	): Promise<UserDto | null> {
		const request: PersonalInfoRequest = {
			firstName: data.firstName,
			lastName: data.lastName,
			phoneNo: data.phoneNo,
			location: data.location,
		};

		try {
			const url = "/api/user/personalInfo";
			const response = await axios.post<UserDto>(url, request);
			if (response.status === 200) {
				return response.data;
			}
		} catch {}

		return null;
	}

	public static async saveAccountInfoAsync(
		data: IProfilePasswordForm
	): Promise<UserDto | null> {
		const request: AccountInfoRequest = {
			currentPassword: data.currentPassword,
			newPassword: data.newPassword,
			confirmPassword: data.confirmNewPassword,
		};

		try {
			const url = "/api/user/accountInfo";
			const response = await axios.post<UserDto>(url, request);
			if (response.status === 200) {
				return response.data;
			}
		} catch {}

		return null;
	}

	public static async updateProfilePictureAsync(
		tmpImageUrl: string
	): Promise<UserDto | null> {
		const request: ProfilePictureRequest = {
			tmpUrl: tmpImageUrl,
		};

		try {
			const url = "/api/user/profilePicture";
			const response = await axios.post<UserDto>(url, request);
			if (response.status === 200) {
				return response.data;
			}
		} catch {}

		return null;
	}
}
