import React from "react";
import { ForgotPassword } from "src/components/user/forgotPassword/forgotPassword";

export const ForgotPasswordView = () => {
	return (
		<>
			<ForgotPassword />
		</>
	);
};
