import factory, { IAppConfig } from "./_app.config";
const appConfig = factory();

export default {
	...appConfig,

	environments: {
		...appConfig.environments,
		isProduction: false,
		isDevelopment: true,
		name: "development",
	},

	domains: {
		legacyWeb: "https://localhost:44304",
		wordpress: "https://beatsunion.com",
		webReact: "https://localhost:44344",
		fileServerUrl: "https://localhost:44344",
	},

	auth: {
		authority: "https://localhost:44361",
		client_id: "BeatsUnion.Web.React",
		response_type: "id_token token",
		scope: "openid profile offline_access api1.read",
		automaticSilentRenew: true,
		redirect_uri: "https://localhost:44344/signin-oidc",
		silent_redirect_uri: "https://localhost:44344/silent-signin-oidc",
		extraQueryParams: {
			signoutUrl: "https://localhost:44344/signout-oidc",
		},
	},

	azure: {
		applicationInsights: {
			instrumentationKey: "c281a936-ad79-4761-8a0e-668e8c791441",
		},
	},

	featureFlags: {
		disableStoreIfSubscriptionIsInactive: false,
	},
} as IAppConfig;
