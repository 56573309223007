import React from "react";
import { CenterScreen } from "src/components/layout/centerScreen";
import { Register } from "src/components/user/registration/register";
import { useQueryString } from "src/utils/hooks";

interface IProps {}

interface IRouteParams {
	code?: string;
	returnUrl?: string;
}

export const RegisterView = (props: IProps) => {
	const params = useQueryString<IRouteParams>();

	return (
		<CenterScreen title="" md={{ size: 12 }} lg={{ size: 10, offset: 1 }}>
			<div className="container">
				<Register
					code={params.code}
					returnUrl={params.returnUrl}
				></Register>
			</div>
		</CenterScreen>
	);
};
