import * as yup from "yup";

export const ForgotPasswordFormSchema = yup
	.object()
	.shape({
		username: yup.string().email().required().max(50).ensure().trim(),
	})
	.required();

export type IForgotPasswordForm = yup.InferType<
	typeof ForgotPasswordFormSchema
>;

export const initialForgotPasswordForm: IForgotPasswordForm = {
	username: "",
};
