const findScriptTag = (searchString: string): HTMLScriptElement | null => {
	const scripts = document.getElementsByTagName("script");
	for (let i = 0; i < scripts.length; i++) {
		const script = scripts[i];
		if (script.src.includes(searchString.toLocaleLowerCase())) {
			return script;
		}
	}

	return null;
};

export const addScriptTag = (
	src: string,
	appendToBody: boolean = true
): void => {
	if (findScriptTag(src)) {
		return;
	}

	const script = document.createElement("script");
	script.src = src;
	if (appendToBody) {
		document.body.appendChild(script);
	} else {
		document.head.appendChild(script);
	}
};

export const removeScriptTag = (src: string): void => {
	const script = findScriptTag(src);
	if (script) {
		script.remove();
	}
};

const domHelpers = {
	addScriptTag,
	removeScriptTag,
};
export default domHelpers;
