type EnvType = {
	REACT_APP_ENV_NAME: string;
	REACT_APP_NAMESPACE: string;
};

declare global {
	interface Window {
		env: EnvType;
	}
}

export const env: EnvType = { ...process.env, ...window.env };
