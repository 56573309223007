import React, { PropsWithChildren } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { ColumnProps } from "reactstrap/lib/Col";

interface IProps {
	title: string;
	xs?: ColumnProps;
	sm?: ColumnProps;
	md?: ColumnProps;
	lg?: ColumnProps;
	xl?: ColumnProps;
}

export const CenterScreen = (props: PropsWithChildren<IProps>) => {
	const { xs, sm, md, lg, xl } = props;
	return (
		<>
			<Row>
				<Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
					<Card>
						<CardBody className="px-2">
							<Row className="py-2">
								<Col xs={{ size: 10, offset: 1 }}>
									<div className="text-center">
										<h1 className="font-family-bold">
											{props.title}
										</h1>
									</div>
									<div>{props.children}</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};
