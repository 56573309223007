import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Col, Row } from "reactstrap";
import { AccountService } from "src/api/services/accountService";
import {
	IAccountInfoForm,
	initialAccountInfoForm,
} from "src/models/Forms/registration/accountInfo";
import {
	initialPersonalInfoForm,
	IPersonalInfoForm,
} from "src/models/Forms/registration/personalInfo";
import { IRegisterInfo } from "src/models/Forms/registration/registerInfo";
import { Wizard } from "../../common/wizard";
import { AccountInfo } from "./accountInfo";
import { PersonalInfo } from "./personalInfo";

interface IProps {
	code?: string;
	returnUrl?: string;
}

interface IState extends IRegisterInfo {
	isCompleted?: boolean;
}

const initialState: IState = {
	personalInfo: initialPersonalInfoForm,
	accountInfo: initialAccountInfoForm,
	code: "",
};

export const Register = (props: IProps) => {
	const history = useHistory();

	const [inProgress, setInProgress] = useState(false);
	const [showError, setShowError] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [isValid, setIsValid] = useState(true);
	const [hasBeenUsed, setHasBeenUsed] = useState(false);

	const [state, setState] = useState<IState>(initialState);
	const { isCompleted } = state;

	const returnUrl = props.returnUrl || "/signin";

	const createAccountAsync = useCallback(async () => {
		setInProgress(true);
		setShowError(false);
		setShowSuccess(false);
		const response = await AccountService.registerAsync(state);
		setInProgress(false);
		if (!response) {
			setShowError(true);
		} else {
			setShowSuccess(true);
			setTimeout(() => {
				if (returnUrl.startsWith("http")) {
					window.location.href = returnUrl;
				} else {
					history.push(returnUrl);
				}
			}, 1000);
		}
	}, [state, history, returnUrl]);

	let { code } = props;
	const checkRegisterCodeAsync = useCallback(async () => {
		const response = await AccountService.checkRegisterCodeAsync(code || "");

		setIsValid(response?.exists ?? true);
		setHasBeenUsed(response?.hasBeenUsed ?? false);
	}, [code, setIsValid, setHasBeenUsed]);

	useEffect(() => {
		const worker = async () => {
			if (isCompleted) {
				await createAccountAsync();
			}
		};
		worker();
	}, [isCompleted, createAccountAsync]);

	useEffect(() => {
		if (code !== "") {
			checkRegisterCodeAsync();
		}
	}, [code, checkRegisterCodeAsync]);

	const onPersonalInfoSubmitted = (values: IPersonalInfoForm) => {
		setState({
			...state,
			personalInfo: values,
		});
	};

	const onAccountInfoSubmitted = async (values: IAccountInfoForm) => {
		setState({
			...state,
			accountInfo: values,
			code: code || "",
			isCompleted: true,
		});
	};

	if (isValid && !hasBeenUsed) {
		return (
			<>
				<Row>
					<Col>
						<Wizard titles={["Personal Info", "Account Info"]}>
							<PersonalInfo onSubmited={onPersonalInfoSubmitted} />
							<AccountInfo
								onSubmitedAsync={onAccountInfoSubmitted}
								showError={showError}
								showSuccess={showSuccess}
								inProgress={inProgress}
							/>
						</Wizard>
					</Col>
				</Row>
			</>
		);
	}

	if (hasBeenUsed) {
		return (
			<Row>
				<Col>
					<h3 className="text-center">
						This registration link has been previously used to create
						another user.
					</h3>
				</Col>
			</Row>
		);
	}

	return (
		<Row>
			<Col>
				<h3 className="text-center">The registration link is not valid.</h3>
			</Col>
		</Row>
	);
};
