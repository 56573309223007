import { Form, Formik } from "formik";
import React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { FormikGooglePlace } from "src/components/common/formik/formikGooglePlace";
import {
	FormikTextbox,
	InputTypes,
} from "src/components/common/formik/formikTextbox";
import { FormikWrapper } from "src/components/common/formik/formikWrapper";
import {
	initialPersonalInfoForm,
	IPersonalInfoForm,
	PersonalInfoFormSchema,
} from "src/models/Forms/registration/personalInfo";
import { nameof } from "ts-simple-nameof";

type IProps = Partial<StepWizardChildProps> & {
	onSubmited: (values: IPersonalInfoForm) => void;
};

export const PersonalInfo = (props: IProps) => {
	const handleSubmit = (values: IPersonalInfoForm) => {
		props.onSubmited && props.onSubmited(values);
		props?.nextStep && props?.nextStep();
	};

	return (
		<>
			<h4 className="mb-2">
				<strong>Personal Info</strong>
			</h4>

			<FormikWrapper>
				<Formik<IPersonalInfoForm>
					initialValues={initialPersonalInfoForm}
					validationSchema={PersonalInfoFormSchema}
					onSubmit={(values /*, actions*/) => {
						handleSubmit(values);
					}}
				>
					{(formikProps) => (
						<Form>
							<div className="row">
								<div className="col-12 col-md-6 mb-1">
									<FormikTextbox
										label="First Name"
										type={InputTypes.text}
										name={nameof<IPersonalInfoForm>(
											(s) => s.firstName
										)}
									/>
								</div>
								<div className="col-12 col-md-6 mb-1">
									<FormikTextbox
										label="Last Name"
										type={InputTypes.text}
										name={nameof<IPersonalInfoForm>(
											(s) => s.lastName
										)}
									/>
								</div>
								<div className="col-12 col-md-6 mb-1">
									<FormikTextbox
										label="Phone Number"
										type={InputTypes.phone}
										name={nameof<IPersonalInfoForm>((s) => s.phoneNo)}
									/>
								</div>
								<div className="col-12 col-md-6 mb-1">
									<FormikGooglePlace
										label="City/Country"
										fieldName={nameof<IPersonalInfoForm>(
											(s) => s.location
										)}
									/>

									{/* <FormikTextbox
										label="City/Country"
										type={InputTypes.text}
										name={nameof<IPersonalInfoForm>(
											(s) => s.location
										)}
									/> */}
								</div>
							</div>
							<div className="pt-2">
								<button
									type="submit"
									className="btn btn-lg btn-primary"
								>
									Next
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</FormikWrapper>
		</>
	);
};
