import * as yup from "yup";
import "yup-phone";

export const PersonalInfoFormSchema = yup
	.object({
		firstName: yup.string().required().max(50).ensure().trim(),

		lastName: yup.string().required().max(50).ensure().trim(),

		phoneNo: yup.string().required().ensure().trim(),

		location: yup.string().required().ensure().trim(),
	})
	.required();

export type IPersonalInfoForm = yup.InferType<typeof PersonalInfoFormSchema>;

export const initialPersonalInfoForm: IPersonalInfoForm = {
	firstName: "",
	lastName: "",
	phoneNo: "",
	location: "",
};
