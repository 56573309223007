import factory, { IAppConfig } from "./_app.config";
const appConfig = factory();

export default {
	...appConfig,

	environments: {
		...appConfig.environments,
		isProduction: false,
		isK8sSandbox: true,
		name: "aks.sandbox",
	},

	domains: {
		...appConfig.domains,
		legacyWeb: "https://beatsunion-web-sandbox.azurewebsites.net",
		wordpress: "https://beatsunion.com",
		webReact: "https://web-react.sandbox.beatsunion.com",
		fileServerUrl: "https://beatsunion-web-sandbox.azurewebsites.net",
	},
	auth: {
		...appConfig.auth,
		authority: "http://bu-identity-k8s.bu-dev.net",
		client_id: "BeatsUnion.Web.React",
		response_type: "id_token token",
		scope: "openid profile offline_access api1.read",
		automaticSilentRenew: true,
		redirect_uri: "http://bu-identity-k8s.bu-dev.net/signin-oidc",
		silent_redirect_uri:
			"http://bu-identity-k8s.bu-dev.net/silent-signin-oidc",
		extraQueryParams: {
			signoutUrl: "http://bu-identity-k8s.bu-dev.net/signout-oidc",
		},
	},
	azure: {
		...appConfig.azure,
		applicationInsights: {
			instrumentationKey: "c281a936-ad79-4761-8a0e-668e8c791441",
		},
	},
} as IAppConfig;
