import { FieldInputProps, FieldMetaProps, useField } from "formik";
import React from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";

interface IPrpos {
	fieldName: string;
	label?: string;
	onBlur?: (
		e: React.FocusEvent<HTMLInputElement>,
		field: FieldInputProps<any>,
		meta: FieldMetaProps<any>
	) => boolean;
	onFocus?: (
		e: React.FocusEvent<HTMLInputElement>,
		field: FieldInputProps<any>,
		meta: FieldMetaProps<any>
	) => void;
}

export const FormikGooglePlace = (props: IPrpos) => {
	const [field, meta, helpers] = useField(props.fieldName);

	const {
		ready,
		suggestions: { status, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		requestOptions: {
			types: ["(cities)"],
			/* Define search scope here */
		},
		debounce: 50,
	});
	const ref = useOnclickOutside(() => {
		clearSuggestions();
	});

	const handleInput = (e: any, onChange: any) => {
		// Update the keyword of the input element
		setValue(e.target.value);
		onChange(e);
	};

	const handleSelect = (props: any) => () => {
		// When user selects a place, we can replace the keyword without request data from API
		// by setting the second parameter to "false"

		const { description } = props;
		setValue(description, false);
		clearSuggestions();

		getGeocode({ address: description })
			.then((results) => {
				helpers.setValue(results[0].formatted_address);
			})
			.catch((error) => {
				console.log("😱 Error: ", error);
			});
	};

	const renderSuggestions = () => {
		return data.map((suggestion) => {
			const {
				place_id,
				structured_formatting: { main_text, secondary_text },
			} = suggestion;

			return (
				<li
					key={place_id}
					className="autocomplete-item"
					onClick={handleSelect(suggestion)}
				>
					<strong>{main_text}</strong> <small>{secondary_text}</small>
				</li>
			);
		});
	};
	const hasError = meta.touched && (meta.error || "") !== "";

	const handleOnBlur = (
		field: FieldInputProps<any>,
		meta: FieldMetaProps<any>,
		e: React.FocusEvent<HTMLInputElement>
	) => {
		let invokeFieldOnBlur = true;
		if (props.onBlur) {
			invokeFieldOnBlur = props.onBlur(e, field, meta) ?? true;
		}

		if (invokeFieldOnBlur) {
			field.onBlur(e);
		}
	};

	const handleOnFocus = (
		field: FieldInputProps<any>,
		meta: FieldMetaProps<any>,
		e: React.FocusEvent<HTMLInputElement>
	) => {
		e.target.setAttribute("autocomplete", "off");
		e.target.select();
		props.onFocus && props.onFocus(e, field, meta);
	};

	const inputName = `bu_gplaces_${encodeURI(props.fieldName)}`;
	const { onChange, ...fieldWithoutOnChange } = field;
	let { label } = props;
	label = label ?? "";

	return (
		<div ref={ref}>
			<div className={`${label !== "" ? "form-outline" : ""}`}>
				<input
					key={`bu_google_place_${props.fieldName}`}
					id={inputName}
					type="text"
					onChange={(e) => handleInput(e, onChange)}
					disabled={!ready}
					className={
						hasError ? "form-control is-invalid active" : "form-control"
					}
					{...fieldWithoutOnChange}
					onBlur={(e) => handleOnBlur(field, meta, e)}
					onFocus={(e) => handleOnFocus(field, meta, e)}
				/>
				{label !== "" && (
					<label className="form-label" htmlFor={inputName}>
						<>{label}</>
					</label>
				)}
			</div>
			{status === "OK" && (
				<div style={{ position: "relative" }}>
					<div className="autocomplete-dropdown-container">
						<div className="autocomplete-dropdown open">
							<ul className="autocomplete-items-list" role="listbox">
								{renderSuggestions()}
							</ul>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
