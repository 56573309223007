import { Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Form, ListGroup, ListGroupItem, Row } from "reactstrap";
import { AccountService } from "src/api/services/accountService";
import AjaxButton from "src/components/common/ajaxButton";
import {
	FormikTextbox,
	InputTypes,
} from "src/components/common/formik/formikTextbox";
import { FormikWrapper } from "src/components/common/formik/formikWrapper";
import { CenterScreen } from "src/components/layout/centerScreen";
import {
	initialResetPasswordForm,
	IResetPasswordForm,
	ResetPasswordFormSchema,
} from "src/models/Forms/forgotPassword/resetPassword";
import { nameof } from "ts-simple-nameof";

interface IProps {
	handleSubmitAsync?: (form: IResetPasswordForm) => Promise<any>;
	ajaxButtonLoading?: boolean;
	showError?: boolean;
	email: string;
	code: string;
}

const RenderInvalid = () => {
	return (
		<>
			<div className="pt-2">
				This reques is not valid. Please submit a new request and follow the
				instructions.
			</div>
			<div className="form-group pt-2">
				<Row>
					<Col xs="12" md="6">
						<Link to="/signin" className="btn btn-primary btn-lg w-100">
							Sign In
						</Link>
					</Col>
					<Col xs="12" md="6">
						<Link
							to="/forgotpassword"
							className="btn btn-default btn-lg w-100"
						>
							Forgot Password
						</Link>
					</Col>
				</Row>
			</div>
		</>
	);
};

const RenderConfirmation = () => {
	return (
		<>
			<div className="pt-2">
				Your password is changed successfully.
				<br />
				Please proceed to the Sign In page.
			</div>
			<div className="form-group pt-2">
				<Row>
					<Col xs="12" md="6">
						<Link to="/signin" className="btn btn-primary btn-lg w-100">
							Sign In
						</Link>
					</Col>
				</Row>
			</div>
		</>
	);
};

const RenderForm = (props: IProps) => {
	const init = {
		...initialResetPasswordForm,
		username: props.email,
	};

	return (
		<>
			<div className="form-group mt-2">
				<FormikWrapper>
					<Formik<IResetPasswordForm>
						initialValues={init}
						validationSchema={ResetPasswordFormSchema}
						onSubmit={async (values /*, actions*/) => {
							await (props.handleSubmitAsync &&
								props.handleSubmitAsync(values));
						}}
					>
						{(formikProps) => (
							<Form>
								<div className="mb-1">
									<FormikTextbox
										type={InputTypes.email}
										label="Email Address"
										disabled={true}
										name={nameof<IResetPasswordForm>(
											(f) => f.username
										)}
									/>
								</div>
								<div className="mb-1">
									<FormikTextbox
										type={InputTypes.password}
										label="New Password"
										name={nameof<IResetPasswordForm>(
											(f) => f.password
										)}
									/>
								</div>
								<div className="mb-1">
									<FormikTextbox
										type={InputTypes.password}
										label="Confirm Password"
										name={nameof<IResetPasswordForm>(
											(f) => f.confirmPassword
										)}
									/>
									<div className="text-muted font-sm">
										Min 6 Characters: an Upper, a Lower, a Number, and
										a Special character
									</div>
								</div>
								<div className="pt-1">
									<Row>
										<Col xs="12" md="6">
											<AjaxButton
												type="button"
												onClick={formikProps.submitForm}
												className="btn btn-lg btn-primary"
												loading={props.ajaxButtonLoading || false}
											>
												Submit
											</AjaxButton>
										</Col>
									</Row>
								</div>
								<div className="pt-1">
									{props.showError && (
										<Row>
											<Col>
												<ListGroup>
													<ListGroupItem color="danger">
														There was an error when submitting
														your request. Please try again later.
													</ListGroupItem>
												</ListGroup>
											</Col>
										</Row>
									)}
								</div>
							</Form>
						)}
					</Formik>
				</FormikWrapper>
			</div>
		</>
	);
};

export const ResetPassword = (props: IProps) => {
	let isInvalid = false;
	if (!props.email || props.email === "" || !props.code || props.code === "") {
		isInvalid = true;
	}

	const [showError, setShowError] = useState(false);
	const [isFormMode, setIsFormMode] = useState(true);
	const [ajaxButtonLoading, setAjaxButtonLoading] = useState(false);

	props.showError = showError;
	props.ajaxButtonLoading = ajaxButtonLoading;
	props.handleSubmitAsync = async (form: IResetPasswordForm): Promise<any> => {
		setShowError(false);
		setAjaxButtonLoading(true);

		const isSuccess = await AccountService.resetPasswordAsync(
			form.username,
			form.password,
			form.confirmPassword,
			props.code
		);

		if (isSuccess) {
			setTimeout(() => {
				setIsFormMode(false);
				setAjaxButtonLoading(false);
			}, 1000);
		} else {
			setShowError(true);
		}
	};

	return (
		<>
			<CenterScreen
				title="Reset Password"
				md={{ size: 10, offset: 1 }}
				lg={{ size: 6, offset: 3 }}
			>
				{isInvalid ? (
					<RenderInvalid />
				) : isFormMode ? (
					<RenderForm {...props} />
				) : (
					<RenderConfirmation />
				)}
			</CenterScreen>
		</>
	);
};
