import * as QueryString from "query-string";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Signin } from "src/components/user/signin";

interface IProps extends RouteComponentProps {}

export const SigninView = (props: IProps) => {
	let returnUrl = "";
	const params = QueryString.parse(props.location.search);
	for (const key in params) {
		if (
			Object.prototype.hasOwnProperty.call(params, key) &&
			key.toLocaleLowerCase() === "returnUrl".toLocaleLowerCase()
		) {
			returnUrl = params[key]?.toString() ?? "";
		}
	}

	return (
		<div className="center-screen">
			<Signin returnUrl={returnUrl} />
		</div>
	);
};
