import { useContext, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { UserService } from "src/api/services/userService";
import { FileUploader } from "src/components/common/fileUploader";
import { SaveCancel } from "src/components/common/saveCancel";
import { UserContext } from "src/components/context/userContext";
import appConfig from "src/utils/appConfig";
import { IProfileComponentProps } from "./profile";

interface IImageUrls {
	oldImageUrl: string;
	currentImageUrl: string;
}

export const ProfilePicture = (props: IProfileComponentProps) => {
	const userContext = useContext(UserContext);

	let [imageUrls, setImageUrls] = useState<IImageUrls>({
		oldImageUrl: "",
		currentImageUrl: props.user.altImageUrl,
	});

	imageUrls.currentImageUrl = imageUrls.currentImageUrl
		?.toLowerCase()
		?.startsWith("http")
		? imageUrls.currentImageUrl
		: appConfig.domains.fileServerUrl + imageUrls.currentImageUrl;

	const cancelImageUpload = () => {
		const oldUrl = imageUrls.oldImageUrl;
		setImageUrls({
			currentImageUrl: oldUrl,
			oldImageUrl: "",
		});
	};

	const saveImage = async () => {
		const user = await UserService.updateProfilePictureAsync(
			imageUrls.currentImageUrl
		);
		userContext?.updateUser(user);
		const oldUrl = imageUrls.oldImageUrl;
		setImageUrls({
			currentImageUrl: user?.altImageUrl || oldUrl,
			oldImageUrl: "",
		});
	};

	return (
		<>
			<Card>
				<CardBody>
					<div className="d-flex align-items-center">
						<div className="flex-grow-0 me-2">
							<img
								className="rounded border p-q"
								alt={props.user.firstName}
								src={`${imageUrls.currentImageUrl}?s=200`}
							/>
						</div>
						<div className="flex-grow-1 me-2">
							<Row>
								<Col xs="12" lg="6" xl="4">
									<div className="mb-2">
										Personalize your account with a photo. Your
										profile photo will appear on pages that use your
										account.
									</div>
								</Col>
							</Row>
							<div>
								{imageUrls.oldImageUrl !== "" ? (
									<SaveCancel
										onSaveAsync={saveImage}
										onCancelAsync={cancelImageUpload}
									/>
								) : (
									<FileUploader
										text="Change Photo"
										onUploadStarted={(item) => {
											console.log("onUploadStarted", item);
										}}
										onUploadProgress={(item) => {
											console.log("onUploadProgress", item);
										}}
										onUploadCompleted={(uploadUrl, item) => {
											console.log("onUploadCompleted", uploadUrl);
											console.log("onUploadCompleted", item);
											setImageUrls({
												currentImageUrl: uploadUrl,
												oldImageUrl: props.user.altImageUrl,
											});
										}}
									/>
								)}
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		</>
	);
};
